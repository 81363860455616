const initialFilterState = {
  searchText: "",
  businessId: 0,
  planType: "",
  status: "",
  clientReportOrderType: "",
  clientRating: 0,
  userTypeId: 0,
  countryId: 0,
  stateId: 0,
  serviceCategoryId: 0,
  serviceId: 0,
  serviceType: "",
  userType: "",
  fromDate: null,
  toDate: null,
  type: "",
  commissionType: "",
  servicePackId: null,
  formType: "",
  payeeType: "",
  bookingType: "",
  serviceProviderId: "",
  rewardClubUsageType: "",
  paymentMode: "",
  discount: "",
  hcpType: "",
  inventoryItemId: 0,
  manufacturer: "",
  supplier: "",
  subjectId: 0,
  stepId: 0,
  userStatus: null,
  consultationType: "",
  clubReady: false
};

export const actionTypes = {
  searchText: "[ST] Filter",
  reset: "[Reset] Filter",
  fromDate: "[FD] Filter",
  toDate: "[TD] Filter",
  businessId: "[BS] Filter",
  status: "[STA] Filter",
  clientReportOrderType: "[CROT] Filter",
  clientRating: "[CR] Filter",
  userTypeId: "[UST] Filter",
  stateId: "[STT] Filter",
  countryId: "[CN] Filter",
  serviceCategoryId: "[SRC] Filter",
  serviceId: "[SRV] Filter",
  serviceType: "[SRT] filter",
  userType: "[UT] Filter",
  commissionType: "[CMS] Filter",
  planType: "[PLT] Filter",
  type: "[CPT] Filter",
  servicePackId: "[SRP] Filter",
  formType: "[FT] Filter",
  payeeType: "[PT] Filter",
  bookingType: "[BT] Filter",
  serviceProviderId: "[SP] Filter",
  rewardClubUsageType: "[RUT] Filter",
  paymentMode: "[PM] Filter",
  discount: "[DT] Filter",
  hcpType: "[HCP] Filter",
  levels: "[Level] Filter",
  logs: "[Logs] Filter",
  mobileOSType: "[MobileOSType] Filter",
  serviceLog: "[ServiceLog] Filter",
  inventoryItemId: "[INVI] Filter",
  manufacturer: "[MFR] Filter",
  supplier: "[SPR] Filter",
  subjectId: "[SUBJ] Filter",
  recipientType: "[RECPT] Filter",
  stepId: "[OBSID] Filter",
  userStatus: "[OBSUS] Filter",
  consultationType: "[CT] Filter",
  clubReady: "[CLR] Filter"
};

export const filterReducer = (state = initialFilterState, action) => {
  switch (action.type) {
    case actionTypes.reset:
      return initialFilterState;
    // case actionTypes.searchText:
    //   return {
    //     ...state,
    //     searchText: action.payload.searchText ?? state.searchText,
    //   };
    case actionTypes.businessId:
      return {
        ...state,
        businessId: action.payload.businessId,
      };
    case actionTypes.status:
      return {
        ...state,
        status: action.payload.status,
      };
    case actionTypes.clientReportOrderType:
      return {
        ...state,
        clientReportOrderType: action.payload.clientReportOrderType,
      };
    case actionTypes.clientRating:
      return {
        ...state,
        clientRating: action.payload.clientRating,
      };
    case actionTypes.userTypeId:
      return {
        ...state,
        userTypeId: action.payload.userTypeId,
      };
    case actionTypes.stateId:
      return {
        ...state,
        stateId: action.payload.stateId,
      };
    case actionTypes.countryId:
      return {
        ...state,
        countryId: action.payload.countryId,
        stateId: 0,
      };
    case actionTypes.serviceCategoryId:
      return {
        ...state,
        serviceCategoryId: action.payload.serviceCategoryId,
      };
    case actionTypes.serviceId:
      return {
        ...state,
        serviceId: action.payload.serviceId,
      };
    case actionTypes.serviceType:
      return {
        ...state,
        serviceType: action.payload.serviceType,
      };
    case actionTypes.userType:
      return {
        ...state,
        userType: action.payload.userType,
      };
    case actionTypes.commissionType:
      return {
        ...state,
        commissionType: action.payload.commissionType,
      };
    case actionTypes.planType:
      return {
        ...state,
        planType: action.payload.planType,
      };
    case actionTypes.type:
      return {
        ...state,
        type: action.payload.type,
      };
    case actionTypes.servicePackId:
      return {
        ...state,
        servicePackId: action.payload.servicePackId,
      };
    case actionTypes.formType:
      return {
        ...state,
        formType: action.payload.formType,
      };
    case actionTypes.fromDate:
      return {
        ...state,
        fromDate: action.payload.fromDate,
      };
    case actionTypes.toDate:
      return {
        ...state,
        toDate: action.payload.toDate,
      };
    case actionTypes.payeeType:
      return {
        ...state,
        payeeType: action.payload.payeeType,
      };
    case actionTypes.bookingType:
      return {
        ...state,
        bookingType: action.payload.bookingType,
      };
    case actionTypes.serviceProviderId:
      return {
        ...state,
        serviceProviderId: action.payload.serviceProviderId,
      };
    case actionTypes.rewardClubUsageType:
      return {
        ...state,
        rewardClubUsageType: action.payload.rewardClubUsageType,
      };
    case actionTypes.paymentMode:
      return {
        ...state,
        paymentMode: action.payload.paymentMode,
      };
    case actionTypes.discount:
      return {
        ...state,
        discount: action.payload.discount,
      };
    case actionTypes.hcpType:
      return {
        ...state,
        hcpType: action.payload.hcpType,
      };
    case actionTypes.levels:
      return {
        ...state,
        levels: action.payload.levels,
      };
    case actionTypes.logs:
      return {
        ...state,
        logs: action.payload.logs,
      };
    case actionTypes.mobileOSType:
      return {
        ...state,
        mobileOSType: action.payload.mobileOSType,
      };
    case actionTypes.serviceLog:
      return {
        ...state,
        serviceLog: action.payload.serviceLog,
      };
    case actionTypes.inventoryItemId:
      return {
        ...state,
        inventoryItemId: action.payload.inventoryItemId,
      };
    case actionTypes.manufacturer:
      return {
        ...state,
        manufacturer: action.payload.manufacturer,
      };
    case actionTypes.supplier:
      return {
        ...state,
        supplier: action.payload.supplier,
      };
    case actionTypes.subjectId:
      return {
        ...state,
        subjectId: action.payload.subjectId,
      };
    case actionTypes.recipientType:
      return {
        ...state,
        recipientType: action.payload.recipientType,
      };
    case actionTypes.stepId:
      return {
        ...state,
        stepId: action.payload.stepId,
      };
    case actionTypes.userStatus:
      return {
        ...state,
        userStatus: action.payload.userStatus,
      };
    case actionTypes.consultationType:
      return {
        ...state,
        consultationType: action.payload.consultationType,
      };
    case actionTypes.clubReady:
      return {
        ...state,
        clubReady: action.payload.clubReady,
      };
    default:
      return state;
  }
};
