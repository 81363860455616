import * as Msal from "@azure/msal-browser";
import { actionTypes } from "../app/modules/widget/_redux/widgetRedux";
import store from "../redux/store";
import {
  msalConfig,
  loginRequest,
  silentRequest,
  tokenRequest,
  resetPasswordRequest,
  passwordReset,
  signInWidgetPolicy,
} from "./authConfig";

// Create the main msalApp instance
// configuration parameters are located at authConfig.js
const msalApp = new Msal.PublicClientApplication(msalConfig);
let accountId = "";
let accessToken = "";

function selectAccount() {
  // If this condition fails it means the request is coming from iFrame.
  if (window.location !== window.parent.location) {
    const currentAccounts = msalApp.getAllAccounts();
    const latestUser = currentAccounts?.find(
      (item) =>
        item.idTokenClaims["tfp"]?.toLowerCase() === signInWidgetPolicy?.toLowerCase()
    );
    accountId = latestUser?.homeAccountId;

    if (!currentAccounts || currentAccounts.length < 1 || !latestUser) {
      console.warn("User not found.");
      store.dispatch({
        type: "[Login] Action",
        payload: {
          user: null,
          authToken: "",
          userName: "",
          isSigninInProgress: false,
        },
      });
      store.dispatch({
        type: actionTypes.SetUserTypeDetail,
        payload: {
          userDetailId: 0,
        },
      });
      return;
    } else if (currentAccounts.length > 1) {
      console.warn("multiple user account detected.");
      store.dispatch({
        type: "[Login] Action",
        payload: {
          user: latestUser,
          authToken: accessToken,
          userName: latestUser?.username,
          isSigninInProgress: false,
        },
      });
    } else if (currentAccounts.length === 1) {
      store.dispatch({
        type: "[Login] Action",
        payload: {
          user: latestUser,
          authToken: accessToken,
          userName: latestUser?.username,
          isSigninInProgress: false,
        },
      });
    }
  }
}

function handleResponse(response) {
  if (response !== null) {
    if (response.accessToken != null) {
      accessToken = response.accessToken;
    }
    if (
      response.idTokenClaims["acr"]?.toLowerCase() ===
      passwordReset?.toLowerCase()
    ) {
      signIn();
    } else {
      selectAccount();
    }
  } else {
    selectAccount();
  }
}
selectAccount();

export const signIn = async () => {
  store.dispatch({
    type: "[Login] Action",
    payload: {
      isSigninInProgress: true,
    },
  });
  msalApp
    .loginPopup()
    .then(handleResponse)
    .catch((error) => {
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        msalApp.loginPopup(resetPasswordRequest).then(() => {
          msalApp.loginPopup(loginRequest).then(handleResponse);
        });
      }
      if (error.errorMessage.indexOf("AADB2C90091") > -1) {
        store.dispatch({
          type: "[Login] Action",
          payload: {
            isSigninInProgress: false,
          },
        });
      }
    });
};

export function signOut() {
  // Choose which account to logout from by passing a username.
  localStorage.clear();
  store.dispatch({
    type: "[Login] Action",
    payload: {
      user: null,
      authToken: "",
      userName: "",
      isSigninInProgress: false,
    },
  });
  store.dispatch({
    type: actionTypes.SetUserTypeDetail,
    payload: {
      userDetailId: 0,
    },
  });
  const logoutRequest = {
    account: msalApp.getAccountByHomeId(accountId),
  };
  msalApp.logoutPopup(logoutRequest);
}

export const acquireAccessToken = async () => {
  silentRequest.account = msalApp.getAccountByHomeId(accountId);
  return msalApp.acquireTokenSilent(silentRequest).catch((error) => {
    console.warn(
      "silent token acquisition fails. acquiring token using interactive method"
    );
    if (error) {
      // fallback to interaction when silent call fails
      tokenRequest.account = msalApp.getAccountByHomeId(accountId);
      return msalApp.acquireTokenPopup(tokenRequest);
    } else {
      console.warn(error);
    }
  });
};
