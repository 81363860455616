/*eslint-disable  eqeqeq*/
import { DataConstant } from "./constants";
const { REACT_APP_WHITELABELNAME_DISPLAYNAME } = process.env;

const LightenDarkenColor = (col, amt) => {
    var usePound = false;

    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}

export const whitelabelThemeConfig = () => {

    var scssConfig = {
        primary: "#2b515c",
                secondary: "#a1a438",
                grayOrDisable: "#aa32ff",

    };

    switch (REACT_APP_WHITELABELNAME_DISPLAYNAME?.toLowerCase()) {

       
        case DataConstant.whiteLabelNames.telemedicine.name.toLowerCase():
            scssConfig = {
                primary: "#2b4168",
                secondary: "#3699ff",
                grayOrDisable: "#aa32ff",
            };
            break;
        case DataConstant.whiteLabelNames.dripbar.name.toLowerCase():
            scssConfig = {
                primary: "#a1a438",
                secondary: "#a1a438",
                grayOrDisable: "#aa32ff",
            };
            break;
        case DataConstant.whiteLabelNames.hydreight.name.toLowerCase():
            scssConfig = {
                primary: "#00B4E5",
                secondary: "#5d9cec",
                grayOrDisable: "#aa32ff",
            };
            break;

        default:
            break;
    }



    document.documentElement.style.setProperty('--default-icon-primary', scssConfig.primary);
    // document.documentElement.style.setProperty('--default-fa-edit', scssConfig.secondary);

    // document.documentElement.style.setProperty('--default-fa-clone', scssConfig.secondary);
    // document.documentElement.style.setProperty('--default-fa-dis', scssConfig.secondary);

    // document.documentElement.style.setProperty('--default-aside-hover', LightenDarkenColor(scssConfig.primary, -3));
    // document.documentElement.style.setProperty('--default-aside-theme', scssConfig.primary);

    // document.documentElement.style.setProperty('--default-aside-highilted-theme', LightenDarkenColor(scssConfig.primary, -3)); // for highlight aside
    // document.documentElement.style.setProperty('--default-brand', LightenDarkenColor(scssConfig.primary, -7)); // for highlight aside
}