/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
/* eslint-disable no-unused-vars*/
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage, Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Demo from "./modules/demo/demo.js";
// import DashboardPage from "./pages/DashboardPage";
import { whitelabelThemeConfig } from "./whitelabelThemeConfig";

export function Routes() {
  const { isAuthorized, isSigninInProgress, userType } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      isSigninInProgress: auth.isSigninInProgress,
      userType: auth.userType,
    }),
    shallowEqual
  );

  whitelabelThemeConfig();

  return (
    <Switch>
      {
        <Route path="/demo" component={Demo} />
        /* {
         <Route path="/dashboard" component={DashboardPage} />
      } */
      }
       <BasePage />
      {/* {!isAuthorized && !isSigninInProgress ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      {!isAuthorized && isSigninInProgress ? (
        <LayoutSplashScreen />
      ) : (
        <Redirect from="/auth" to="/" />
      )}
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      {!isAuthorized ? (
        <Redirect to="/auth/login" />
      ) : (
        <BasePage />
      )} */}
      <Route path="/error" component={ErrorsPage} />
    </Switch>
  );
}
