import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { LoaderSlice } from "./loader/loaderSlice";
import { filterReducer } from "./filter/filterReducer";
import { widgetReducer } from "../app/modules/widget/_redux/widgetRedux";
import { consultationReducer } from "../app/modules/widget/_redux/consultationRedux";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  loader: LoaderSlice.reducer,
  filter: filterReducer,
  widget: widgetReducer,
  consultation : consultationReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
