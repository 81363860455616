import { createSlice } from "@reduxjs/toolkit";

const initialLoaderState = {
  actionLoader: false,
  apiCount: 0,
};

export const LoaderSlice = createSlice({
  name: "loader",
  initialState: initialLoaderState,
  reducers: {
    setLoader: (state, action) => {
      state.actionLoader = true;
      state.apiCount = action.payload.apiCount;
    },

    removeLoader: (state, action) => {
      state.actionLoader = false;
    },  
  },
});
