import React from "react";
import "../../../_metronic/_assets/sass/pages/error/error-4.scss";

export default function Demo() {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-row-fluid bgi-size-cover bgi-position-center">
        <div className="d-flex flex-column flex-row-fluid align-items-center align-items-md-start justify-content-md-center text-center text-md-left px-10 px-md-30 py-10 py-md-0 line-height-xs">
          <h1 className="text-success font-weight-boldest line-height-sm">
            Hello!
          </h1>
          <p className="display-4 text-danger font-weight-boldest mt-md-0 line-height-md">
            Nothing left to do here.
          </p>
        </div>
      </div>
    </div>
  );
}
